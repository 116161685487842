.app-header {
  position: absolute;
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  height: 70px;
  z-index: 100;
  background-color: white;
  border-radius: 0 0 3px 3px;
  box-shadow: 0px 0px 40px 0px rgb(0 0 0 / 10%);
}
.mynav {
  /* background-color: white; */
  border-radius: 0 0 3px 3px;
  box-shadow: 0px 0px 40px 0px rgb(0 0 0 / 10%);
}
.mynav-header {
  background-color: white;
  /* position: sticky; */
  top: 0;
  z-index: 999;
}
.header-top {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 70px;
  padding-left: 20px;
}
.header-left {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 20;
}
.header-menu-item {
  margin-right: 20px;
  color: #2b2b2b;
  font-weight: 600;
  font-size: 0.875rem;
  text-decoration: none !important;
  position: relative;
  cursor: pointer;
}
.header-menu-item:hover {
  color: #2b2b2b;
}
.header-menu-item.active:after {
  display: block;
}
.header-menu-item:after {
  content: "";
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background-color: #47d7ac;
  height: 2px;
  display: none;
}
.header-logo {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.header-logo > div {
  cursor: pointer;
}

.header-right {
  display: flex;
  position: relative;
  z-index: 20;
  padding-right: 20px;
}
.header-right svg {
  cursor: pointer;
}
.cart-item-icon {
  padding-top: 20px;
  margin-right: 20px;
  /* height: 1.2rem;
  width: 1.2rem; */
}
.cart-item {
  /* font-size: 1.25rem; */
  cursor: pointer;
  position: relative;
  /* color: black !important; */
}
.cart-item span {
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 10;
  background-color: orange;
  padding: 1px 3px;
  border-radius: 5px;
  font-size: 0.813rem;
  line-height: 1em;
  display: flex;
  justify-content: center;
}
.user-info {
  font-size: 1.25rem;
  padding-top: 20px;
}
.user-info-menu {
  position: absolute;
  background-color: white;
  top: calc(100% + 1px);
  right: 0;
  border-radius: 0 3px 3px 0;
  width: 180px;
  font-size: 0.875rem;
  height: 0;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 10%);
  overflow: hidden;
  transition: all 0.25s ease-in-out;
}
.user-info-menu.active {
  height: 110px;
}
.user-info-inner {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.login {
  padding: 3px 10px;
  background-color: #6d6d8d;
  color: white !important;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
}
.login.logout {
  background-color: red;
}
.signup-text {
  font-size: 0.875rem;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  color: grey;
  line-height: 0.875rem;
}
.signup-text:hover {
  color: black;
}
.user-info-menu a {
  text-decoration: none;
}

.header-bottom {
  position: absolute;
  top: calc(100% + 1px);
  width: 100%;
  background-color: white;
  left: 0;
  border-radius: 3px 3px 0 0;
  padding: 0 10%;
  display: flex;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  height: 0;
}
.header-bottom.header-expand {
  height: 200px;
}
.header-bottom-item {
  width: 25%;
  padding-top: 30px;
  padding-left: 20px;
}
.header-bottom-item .top {
  color: #51d9b1;
  font-weight: 600;
  margin-bottom: 10px;
}
.header-bottom-item .bottom {
  display: flex;
  flex-direction: column;
}
.bottom a {
  color: #686868;
  margin-bottom: 5px;
  font-size: 0.875rem;
}
/* .header-bottom-item:nth-child(even){
  background-color: #F9F9F9;
} */

.search-wrap {
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 330px;
  transition: all 0.25s ease-in-out;
  background-color: #f4f4f4;
  height: 40px;
  margin-top: 18px;
  margin-right: 20px;
  padding: 0 10px;
}
.search-icon {
  font-size: 1.25rem;
}
.search-input {
  width: 300px;
  transition: all 0.25s ease-in-out;
  border: none;
  background-color: inherit;
  outline: none;
  overflow: hidden;
  font-size: 0.875rem;
}
.search-wrap.hide-search {
  width: 45px;
  margin-right: 10px;
  transition: all 0.25s ease-in-out;
  background-color: initial;
}
.search-wrap.hide-search .search-input {
  width: 0;
}
.search-wrap.hide-search .close-icon {
  width: 0;
}

.logged-as {
  font-size: 0.813rem;
  line-height: 0.75rem;
  margin-bottom: 5px;
}

.navbar-cat-dropdown {
  position: absolute;
}
.drop-down-image > .dropdown-toggle {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.dropdown-menu {
  position: absolute !important;
}

/* .dropdown-menu > a {
  justify-content: center;
} */

.logged-as-firstname {
  max-width: 110px;
}

@media (max-width: 991px) {
  .dropdown-menu[data-bs-popper] {
    left: 1.6rem;
    margin-top: -2rem;
    padding: 0px 10px;
    min-width: 7rem;
  }
}